







































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Global, User, Groups, Reports, Props } from '@/store';
import Gmap from '@/pages/mapView/components/map/GMap.vue';
import CreateGroup from './CreateGroup.vue';
import API, { Types } from '@/modules/API';
import cloneDeep from 'lodash/cloneDeep';

@Component({
  components: {
    CreateGroup,
    Gmap
  }
})
export default class NewGroup extends Vue {
  @Global.State('lang') lang;
  @User.State('project') project;
  @Props.State('list') projectProperties;
  @Reports.State('reportsList') devices;
  @Reports.State('devicesCount') devicesCount;
  @Reports.Getter('commissionedFixtures') commissionedFixtures;
  @Reports.Getter('commissionedFixtures_map') commissionedFixtures_map;
  @Groups.Action('edit') edit;

  groupDevices = new Map();
  createMode = false;
  group = null;
  loading = true;

  rules = {
    required: (value) => !!value || 'Required.'
  };

  async mounted() {
    await this.loadData();
  }

  @Watch('devices')
  @Watch('devicesCount')
  async loadData() {
    if (this.devices.length !== this.devicesCount) return;
    this.loading = true;
    this.groupDevices = new Map();
    this.createMode = !this.$route.params.id;
    if (!this.createMode) await this.setGroupData();
    this.loading = false;
  }

  clear() {
    [...this.groupDevices.values()].forEach((device) => this.$refs.map['updateFeatureIcon'](device));
    this.groupDevices = new Map();
  }

  async setGroupData() {
    const group_id = this.$route.params.id;
    this.group = await API.get(Types.PROJECTS, `${this.project.id}/groups/${group_id}`);

    if (this.group && this.group.devices.length) {
      this.group.devices.forEach((device_id) => {
        if (this.commissionedFixtures_map.has(device_id))
          this.groupDevices.set(device_id, this.commissionedFixtures_map.get(device_id));
      });
    }
  }

  updateSelectedDevices(devices, state) {
    this.groupDevices = cloneDeep(this.groupDevices);
    if (state === true) {
      devices.forEach((device) => {
        if (!this.groupDevices.has(device.id)) {
          this.groupDevices.set(device.id, device);
          this.$refs.map['updateFeatureIcon'](device);
        }
      });
    } else if (state === false) {
      devices.forEach((device) => {
        if (this.groupDevices.has(device.id)) {
          this.groupDevices.delete(device.id);
          this.$refs.map['updateFeatureIcon'](device);
        }
      });
    } else {
      devices.forEach((device) => {
        this.groupDevices.has(device.id)
          ? this.groupDevices.delete(device.id)
          : this.groupDevices.set(device.id, device);
        this.$refs.map['updateFeatureIcon'](device);
      });
    }
  }
  selectAll(devices) {
    this.groupDevices = new Map();
    if (devices.length)
      devices.forEach((device) => this.groupDevices.set(device.id, device));
  }
}
